import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Button, Input } from 'reactstrap';

import personaldata from '../_constants/personal.json';

class Home extends Component {

  state = {
    name: personaldata.name,
  }

  render () {
    return (
      <Container className="homepage content--inner">
          <h1 className="homeheading">{ this.state.name }</h1>
          <div className="form-group">
            <Link to="/personal" className="cv-link">Bekijk mijn projecten</Link>
          </div>
      </Container>
    )
  }
}

export default Home