import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Form, FormGroup, Label, Button, Input } from 'reactstrap';
import data from '../_constants/educations.json';

class Education extends Component {

  state = {
    educations: data.educations
  }

  render () {
    return (
      <div style={{ minHeight: "100vh", backgroundColor: "#e9ecef"}}>
        <Jumbotron className="content"  style={{ background: "rgb(40,40,40)", marginBottom: 0, filter: "drop-shadow(0 0 0 0.75 black)" }}>
          <Container >
            <Row><Col sm="12" md={{ size: 6, offset: 3 }}><h1 style={{ textAlign: "center", color: "white" }}>Opleidingen</h1></Col></Row>
          </Container>
        </Jumbotron>
        <Jumbotron className="content--inner">
          <Container >
            { this.state.educations.map((value, index) => {
              return (
              <Row>
                <Col style={{ textAlign: "left" }} sm="12" md={{ size: 6, offset: 3 }}>
                  <h5>School: { value.school }</h5>
                  <h5>Locatie: { value.city }</h5>
                  <h5>Opleiding: { value.education }</h5>
                  <hr/>
                </Col>
              </Row>
              )
            }) }
          </Container>
        </Jumbotron>
      </div>
    )
  }
}

export default Education