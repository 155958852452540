import React, { Component } from 'react';
import {
  Nav,
  NavItem,
  NavLink as Navlink
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export class AppNavbar extends Component {
  render() {
    return (
      <Nav className="menu" >
        <NavItem className="backButton">
          <Navlink><NavLink to="/"><FontAwesomeIcon icon={ faArrowLeft } /></NavLink></Navlink>
        </NavItem>
      </Nav>
    );
  }
}