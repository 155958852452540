import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from '../_components';
import { history } from '../_helpers';
import { Jumbotron, Container } from 'reactstrap';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '../_helpers/timeline';

import './App.css';
// import Experience from '../components/Experience';

// Pages
import Personal from '../pages/Personal';
import Home from '../pages/Home';
import Education from '../pages/Education';
// import { Login } from '../Login';
// import { Register } from '../Register';
// import { Room } from '../Room';
// import { Profile } from '../Profile';

// Components
 import { AppNavbar } from '../_components';

class App extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div>
            <Router history={history}>
              <div>
                <Route render={({ location }) => {
                  const { pathname, key } = location;

                  return (
                    <TransitionGroup component={null}>
                      <Transition
                        key={key}
                        appear={true}
                        onEnter={(node, appears) => play(pathname, node, appears)}
                        onExit={(node, appears) => exit(node, appears)}
                        timeout={{enter: 750, exit: 150}}
                        >
                        <Switch location={location}>
                          <Route exact path="/" component={Home} />
                          <Route path="/personal" component={Personal} />
                          {/* <Route path="/education" component={Education} /> */}
                          {/* <Route path="/experience" component={Experience} />
                          <Route path="/skills" component={Skills} /> */}
                        </Switch>
                      </Transition>
                    </TransitionGroup>
                  )
                  }}/>
              </div>
            </Router>
          </div>
        );
    }
}

export default App