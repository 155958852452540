import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Form, FormGroup, Label, Button, Input } from 'reactstrap';

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import personaldata from '../_constants/personal.json';
import projectdata from '../_constants/projects.json';
import { AppNavbar } from '../_components';

import me from '../_constants/jpn-1.jpeg';
import githubLogo from '../_constants/github.png';

class Personal extends Component {

  state = {
    name: personaldata.name,
    projects: projectdata.projects
  }

  render () {
    const data = this.state;
    return (
      <div style={{ minHeight: "100vh", backgroundColor: "#e9ecef"}}>
        <AppNavbar />
        <Jumbotron className="content" style={{ borderRadius: "0em", background: "rgb(40,40,40)", marginBottom: 0, filter: "drop-shadow(0 0 0 0.75 black)" }}>
          <Container >
            <Row><Col sm="12" md={{ size: 6, offset: 3 }}><img className="myFace" src={ me } alt="me, myself and i" /></Col></Row>
            <br/>
            <Row><Col sm="12" md={{ size: 6, offset: 3 }}><h1 style={{ textAlign: "center", color: "white" }}>{ data.name }</h1></Col></Row>
          </Container>
        </Jumbotron>
        <Jumbotron style={{ marginBottom: 0, paddingBottom: "150px" }} className="content--inner">
        <Container >
            <Row><Col sm="12" md={{ size: 6, offset: 3 }}><h1 style={{ textAlign: "center" }}>Mijn projecten</h1></Col></Row>
            <br/>
            {data.projects.map((value, index) => {
              return (
                index % 2 == 1 ? (
                  <Row className="projectsRow" >
                    <Col className="projectInfo">
                      <div className="projectContent">
                        <h4>{value.name}</h4>
                        <p>{value.description}</p>
                        {value.tools.map((img, index) => {
                          return <img style={{ maxHeight: "50px" }} src={img} />
                        })}
                      </div>
                    </Col>
                    <Col className="projectLink">
                      <a href={value.link}><img src={githubLogo} /></a>
                    </Col>
                  </Row>) : (
                  <Row className="projectsRow" >
                      <Col className="projectLink">
                      <a href={value.link}><img src={githubLogo} /></a>
                    </Col>
                      <Col className="projectInfo">
                        <div className="projectContent">
                          <h4>{value.name}</h4>
                          <p>{value.description}</p>
                          {value.tools.map((img, index) => {
                          return <img style={{ maxHeight: "50px" }} src={ img } />
                          })}
                        </div>
                    </Col>
                  </Row> )                  
                )
            })}
          </Container>
        </Jumbotron>
      </div>
    )
  }
}

export default Personal